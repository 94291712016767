import * as axios from 'axios';
import {_arrayBufferToBase64, log} from "./utils";
import Integration from "../model/Integration";
import { LocalFireDepartment } from '@mui/icons-material';

export default class Api {
    
    constructor() {
        this.api_url = process.env.REACT_API_ENDPOINT;
    }

    init = () => {
        let headers = {
            Accept: "application/json",
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 30000,
            headers: headers,
        });

        return this.client;
    };

    logout = () => {
        return this.init().post("/ideesaml/login/logout");
    };


    checkSession = () => {
        const api = this.init();
        async function check() {
            try {
                let response = await api.post("/ideesaml/login/check/session");
                if (response.data) {
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject("Response data is undefined");
                }
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return check();
    };

    checkSessionLoggedIn = () => {
        const api = this.init();
        async function checkSession() {
            try {
                let response = await api.post("/ideesaml/login/check");
                if (response.data) {
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject("Response data is undefined");
                } 
            } catch (error) {
                return Promise.reject(error);
            }
        }

        return checkSession();
    }

    refreshSession = () => {
        return this.init().post("/ideesaml/login/refreshSession");
    };

    getQrCode = () => {
        const api = this.init();
        async function getQrCode(timestamp) {
            try {
                let response = await api.get("/ideesaml/login/qrcode", {
                    params: {
                        t: timestamp
                    }
                });
                if (response.data !== null ) {
                    log(response.data);
                    let base64Img = response.data.img;
                    let qrUrl = "data:image/png;base64," + base64Img;
                    log(qrUrl);
                    return Promise.resolve(qrUrl);
                }
            } catch (error) {
                log(error)
            }
        }
        return getQrCode(Date.now());
    };

    getQrCodeSetup = () => {
        const api = this.init();
        async function getQrCodeSetup(timestamp) {
            try {
                let response = await api.get("/ideesaml/login/qrcodeSetup", {
                    params: {
                        t: timestamp
                    }
                });
                if (response.data !== null ) {
                    log(response.data);
                    let base64Img = response.data.img;
                    let qrUrl = "data:image/png;base64," + base64Img;
                    log(qrUrl);
                    return Promise.resolve(qrUrl);
                }
            } catch (error) {
                log(error)
            }
        }
        return getQrCodeSetup(Date.now());
    };


    setupPrepare = (firstName, lastName, companyName) => {
        return this.init().post("/ideesaml/login/setupPrepare", {
            firstName: firstName,
            lastName: lastName,
            companyName: companyName
        });
    };

    createSalesForce = (email, firstName, lastName, companyName) => {
        return this.init().post("/ideesaml/login/salesforce-data", {
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName
        });
    };

    createMagicLink = (email, domain, integrationId, lang) => {
        return this.init().post("/ideesaml/add-managed-user", {
            email: email,
            domain: domain,
            integrationId: integrationId,
            lang: lang
        });
    };

    createCode = (email, domain, integrationId, lang) => {
        return this.init().post("/ideesaml/generate-managed-code", {
            email: email,
            domain: domain,
            integrationId: integrationId,
            lang: lang
        });
    };

    updateCode = (email, integrationId, numberOfUsages, validFromTime) => {
        return this.init().post("/ideesaml/update-managed-code", {
            email: email,
            integrationId: integrationId,
            numberOfUsages: numberOfUsages,
            validFromTime: validFromTime
        });
    };

    revokeCode = (email) => {
        return this.init().post("ideesaml/search/revoke-managed-code", {
            email: email
        });
    };

    revokeInviteLink = (email) => {
        return this.init().post("ideesaml/search/revoke-invite-link", {
            email: email
        });
    };

    sendMailToSales = (isSalesforce, firstName, lastName, companyName) => {
        return this.init().post("/app/sendMailToSales", {
            isSalesforce: isSalesforce,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName
        });
    }

    downloadReport = () => {
        window.location.assign("/ideesaml/get-reports");
    }

    downloadCert = () => {
        window.location.assign("/app/download");
    }

    deleteAccountCheck = () => {
        return this.init().get("/app/deleteAccountCheck");
    }

    deleteAccount = () => {
        return this.init().delete("/app/deleteAccount");
    }

    grantAdminAccess = (email) => {
        return this.init().post("/ideesaml/grant-admin-access", {
            email: email
        });
    }

    revokeAdminAccess = (email) => {
        return this.init().post("/ideesaml/revoke-admin-access", {
            email: email
        });
    }

    checkIfAdminExists = (email) => {
        return this.init().post("/ideesaml/checkAdmin", {
            email: email
        });
    }

    getAdmins = () => {
        return this.init().get("/ideesaml/get-admins");
    }

    searchUsers = (userEmail) => {
        return this.init().post("/ideesaml/search/user", {
            userEmail: userEmail,
        });
    }

    deleteDevice = (deviceData) => {
        return this.init().post("/ideesaml/search/deleteDevice", {
            deviceUuid: deviceData.deviceUuid,
            ownerEmail: deviceData.ownerEmail
        });
    }

    deleteUser = (userDataEmail) => {
        return this.init().post("/ideesaml/search/deleteUser", {
            email: userDataEmail
        });
    }

    upload = (file, integrationName, serviceProvider, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, editMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, siemType, siemWorkspaceId, siemPrimaryKey, siemServerPort, siemServerHostname) => {
        log("File from service: ");
        log(file);
        log("Integration name: " + integrationName);
        log("Service provider: " + serviceProvider);
        log("WEBAUTHN ENABLED: " + webauthnEnabled);
        log("AUTHN ENABLED: " + authnEnabled);
        log("CMLA ENABLED: " + cmlaEnabled);
        log("WEBAUTHN_ACCESS_KEY ENABLED: " + webauthnAccessKeyEnabled);
        log("Integration id: " + integrationId);
        var form = new FormData();
        form.append("file", file);
        form.append("name", integrationName);
        form.append("serviceProvider", serviceProvider);
        form.append("webauthnEnabled", webauthnEnabled);
        form.append("cmlaEnabled", cmlaEnabled);
        form.append("authnEnabled", authnEnabled);
        form.append("webauthnAccessKeyEnabled", webauthnAccessKeyEnabled);
        form.append("editMode", editMode);
        form.append("integrationId", integrationId);
        form.append("scimEnabled", isScimEnabled);
        form.append("scimClientId", scimClientId);
        form.append("scimSecretToken", scimSecretToken);
        form.append("siemEnabled", siemEnabled);
        form.append("siemType", siemType);
        form.append("siemWorkspaceId", siemWorkspaceId);
        form.append("siemPrimaryKey", siemPrimaryKey);
        form.append("siemServerPort", siemServerPort);
        form.append("siemServerHostname", siemServerHostname);

        return axios.post(
            "/app/upload", 
            form,
            {
                headers: {
                    "Content-type": "multipart/form-data"
                }
        });
    }

    addNewClientWithDomain = (domain, integrationName, serviceProvider, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, siemType, siemWorkspaceId, siemPrimaryKey, siemServerPort, siemServerHostname) => {
        log("Domain: " + domain);
        log("Integration name: " + integrationName);
        log("Service provider: " + serviceProvider);
        log("WEBAUTHN ENABLED: " + webauthnEnabled);
        log("AUTHN ENABLED: " + authnEnabled);
        log("CMLA ENABLED: " + cmlaEnabled);
        log("WEBAUTHN_ACCESS_KEY_ENABLED: " + webauthnAccessKeyEnabled);
        
        log("Is edit mode: " + isEditMode);
        let isEditModeValue = (isEditMode !== undefined) ? isEditMode : false;
        log("Is edit mode value: " + isEditModeValue);
        log("Is SCIM enabled: " + isScimEnabled);
        return this.init().post("/app/addNewClientWithDomain", {
            domain: domain,
            integrationName: integrationName,
            serviceProvider: serviceProvider,
            webauthnEnabled: webauthnEnabled,
            authnEnabled: authnEnabled,
            cmlaEnabled: cmlaEnabled,
            webauthnAccessKeyEnabled: webauthnAccessKeyEnabled,
            editMode: isEditModeValue,
            integrationId: integrationId,
            scimEnabled: isScimEnabled,
            scimClientId: scimClientId,
            scimSecretToken: scimSecretToken,
            siemEnabled: siemEnabled,
            siemType: siemType,
            siemWorkspaceId: siemWorkspaceId,
            siemPrimaryKey: siemPrimaryKey,
            siemServerPort: siemServerPort,
            siemServerHostname: siemServerHostname

        });
    }

    addNewTeamviewerClient = (customerIdentifier, clientId, integrationName, serviceProvider, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, siemType, siemWorkspaceId, siemPrimaryKey, siemServerPort, siemServerHostname) => {
        log("ClientId: " + clientId);
        log("Integration name: " + integrationName);
        log("Service provider: " + serviceProvider);
        log("WEBAUTHN ENABLED: " + webauthnEnabled);
        log("AUTHN ENABLED: " + authnEnabled);
        log("CMLA ENABLED: " + cmlaEnabled);
        log("WEBAUTHN_ACCESS_KEY ENABLED: " + webauthnAccessKeyEnabled)
        log("Integration id: " + integrationId);
        return this.init().post("/app/addNewTeamviewerClient", {
            customerIdentifier: customerIdentifier,
            clientId: clientId,
            integrationName: integrationName,
            serviceProvider: serviceProvider,
            webauthnEnabled: webauthnEnabled,
            authnEnabled: authnEnabled,
            cmlaEnabled: cmlaEnabled,
            webauthnAccessKeyEnabled: webauthnAccessKeyEnabled,
            editMode: isEditMode,
            integrationId: integrationId,
            scimEnabled: isScimEnabled,
            scimClientId: scimClientId,
            scimSecretToken: scimSecretToken,
            siemEnabled: siemEnabled,
            siemType: siemType,
            siemWorkspaceId: siemWorkspaceId,
            siemPrimaryKey: siemPrimaryKey,
            siemServerPort: siemServerPort,
            siemServerHostname: siemServerHostname
        })
    }

    generateAndAdd = (domain, integrationName, serviceProvider, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, siemType, siemWorkspaceId, siemPrimaryKey, siemServerPort, siemServerHostname) => {
        log("Domain: " + domain);
        log("Integration name: " + integrationName);
        log("Service provider: " + serviceProvider);
        log("WEBAUTHN ENABLED: " + webauthnEnabled);
        log("AUTHN ENABLED: " + authnEnabled);
        log("CMLA ENABLED: " + cmlaEnabled);
        log("WEBAUTHN_ACCESS_KEY ENABLED: " + webauthnAccessKeyEnabled);
        log("Integration id: " + integrationId);
        return this.init().post("/app/generateAndAdd", {
            domain: domain,
            integrationName: integrationName,
            serviceProvider: serviceProvider,
            webauthnEnabled: webauthnEnabled,
            authnEnabled: authnEnabled,
            cmlaEnabled: cmlaEnabled,
            webauthnAccessKeyEnabled: webauthnAccessKeyEnabled,
            editMode: isEditMode,
            integrationId: integrationId,
            scimEnabled: isScimEnabled,
            scimClientId: scimClientId,
            scimSecretToken: scimSecretToken,
            siemEnabled: siemEnabled,
            siemType: siemType,
            siemWorkspaceId: siemWorkspaceId,
            siemPrimaryKey: siemPrimaryKey,
            siemServerPort: siemServerPort,
            siemServerHostname: siemServerHostname
        });
    }

    save = (url, integrationName, serviceProvider, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, isScimEnabled, scimClientId, scimSecretToken, siemEnabled, siemType, siemWorkspaceId, siemPrimaryKey, siemServerPort, siemServerHostname) => {
        log("Url: " + url);
        log("Integration name: " + integrationName);
        log("Service provider: " + serviceProvider);
        log("WEBAUTHN ENABLED: " + webauthnEnabled);
        log("AUTHN ENABLED: " + authnEnabled);
        log("CMLA ENABLED: " + cmlaEnabled);
        log("WEBAUTHN_ACCESS_KEY ENABLED: " + webauthnAccessKeyEnabled);
        log("IntegrationId: " + integrationId);
        return this.init().post("/app/save", {
            url: url,
            integrationName: integrationName,
            serviceProvider: serviceProvider,
            webauthnEnabled: webauthnEnabled,
            authnEnabled: authnEnabled,
            cmlaEnabled: cmlaEnabled,
            webauthnAccessKeyEnabled: webauthnAccessKeyEnabled,
            editMode: isEditMode,
            integrationId: integrationId,
            scimEnabled: isScimEnabled,
            scimClientId: scimClientId,
            scimSecretToken: scimSecretToken,
            siemEnabled: siemEnabled,
            siemType: siemType,
            siemWorkspaceId: siemWorkspaceId,
            siemPrimaryKey: siemPrimaryKey,
            siemServerPort: siemServerPort,
            siemServerHostname: siemServerHostname
        });
    }

    generateAppCredentials = () => {
        return this.init().get("/app/generateAppCredentials");
    }

    generateSCIMCredentials = () => {
        return this.init().get("/app/generateSCIMCredentials");
    }

    getTeamviewerEndpointSso = () => {
        return this.init().get("app/generateClientId");
    }

    saveDataOidc = (clientID, url, valueSigning, valueEncryptAlg, valueEncryptMeth, valueAuthMeth, clientSecret, integrationName, webauthnEnabled, authnEnabled, cmlaEnabled, webauthnAccessKeyEnabled, isEditMode, integrationId, siemEnabled, siemType, siemWorkspaceId, siemPrimaryKey, siemServerPort, siemServerHostname) => {
        log("Is edit mode: " + isEditMode);
        log("Integration id: " + integrationId);
        
        return this.init().post("/openId/saveData", {
            clientID: clientID,
            url: url,
            signingAlgorithm: valueSigning,
            encryptAlgorithm: valueEncryptAlg,
            encryptMethod: valueEncryptMeth,
            authenticationMethod: valueAuthMeth,
            authenticationKey: clientSecret,
            nameOfIntegration: integrationName,
            webauthnEnabled: webauthnEnabled,
            authnEnabled: authnEnabled,
            cmlaEnabled: cmlaEnabled,
            webauthnAccessKeyEnabled: webauthnAccessKeyEnabled,
            editMode: isEditMode,
            integrationId: integrationId,
            siemEnabled: siemEnabled,
            siemType: siemType,
            siemWorkspaceId: siemWorkspaceId,
            siemPrimaryKey: siemPrimaryKey,
            siemServerPort: siemServerPort,
            siemServerHostname: siemServerHostname
        });
    }

    deleteIntegration = (integrationID) => {
        return this.init().post("/app/deleteIntegration", { 
            id: integrationID
        });
    }

    deletegSuiteClientandSDKConnection = (uuid) => {
        return this.init().post("/app/deletegSuiteClientandSDKConnection", {
            uuid: uuid
        });
    }

    deleteOIDCIntegration = (id) => {
        return this.init().post("/openId/deleteOIDCIntegration", { 
            id: id
        });
    }

    deleteUrl = (integrationID) => {
        return this.init().post("/app/delete", {
            id: integrationID
        });
    }

    getIntegrations = () => {
        return this.init().get("/app/getIntegrationsForUser");
    }

    checkDomain = (domain) => {
        const api = this.init();
        async function checkDomain(domain) {
            try {
                let response = await api.get("/app/checkDomain?domain=" + domain);
                log(response);
                if (response.data != null) {
                    const {data} = response;
                    const isInDatabase = data.inDatabase;
                    return Promise.resolve(isInDatabase);
                }
            } catch (error) {
                log(error);
            }
        }

        return checkDomain(domain);

    }

    getIntegrationsByOwner = () => {
        const api = this.init();
        async function getIntegrationsByOwner() {
            try {
                let response = await api.get("/app/getIntegrationsForUser");
                if (response.data !== null ) {
                    const {data} = response;
                    const groupByOwner = data.reduce((groups, integration) => {
                            const { ownerEmail } = integration;
                            groups[ownerEmail] = groups[ownerEmail] ?? [];
                            groups[ownerEmail].push(integration);
                            return groups
                        }, {}
                    );
                    return Promise.resolve(groupByOwner);
                }
            } catch (error) {
                log(error)
            }
        }
        return getIntegrationsByOwner();
    }
}
